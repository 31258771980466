<nav class="navbar">
    <div class="container-fluid">
        <div class="navbar-header">
            <div class="navbar-left navbar-logo-container">
                <div class="navbar-logo-container-inner">
                    <a routerLink="/home">
                        <img
                            class="navbar-logo"
                            src="{{ config.app.assets.logoMedium }}"
                            alt="{{ config.plataforma.info.companyName }}"
                        />
                    </a>
                </div>
            </div>
            <a class="skip-to-content-link" (click)="skipToContent('main-content')" href="javascript:void(0)">
                Saltar navegaci&oacute;n
            </a>
            <button type="button" class="navbar-toggle" (click)="toggleSidemenu()">
                <span class="sr-only">Toggle navigation</span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
            </button>
            <button type="button" class="navbar-toggle" data-toggle="collapse" data-target=".user-menu">
                <span class="sr-only">Toggle navigation</span>
                <fa [name]="'cog'" class="icon-toggle"></fa>
            </button>
        </div>
        <div *ngIf="authService.isLoggedIn()" class="clearfix user-menu collapse">
            <ul class="nav navbar-top-links navbar-right">
                <li>
                    <a routerLink="/home">
                        <span class="centered-icons">
                            <ogr-icon [name]="'home-o'"></ogr-icon>
                        </span>
                    </a>
                </li>
                <!--<li>
					<a><fa [name]="'question-circle-o'"></fa>Ayuda</a>
				</li>-->
                <li>
                    <a (click)="goToProfile()">{{ usuario_nombre | easyplaceholder: "Usuario" }}</a>
                </li>
                <li class="dropdown last-list-item">
                    <a class="dropdown-toggle" data-toggle="dropdown" href="#">
                        <div class="clearfix">
                            <div class="user-circle-container pull-left">
                                <img
                                    *ngIf="usuario_avatar"
                                    alt="usuario avatar"
                                    class="avatar"
                                    [src]="usuario_avatar_safe"
                                />
                                <fa *ngIf="!usuario_avatar" class="fa-placeholder" [name]="'user'"></fa>
                            </div>
                            <div class="pull-right little-arrow-user">
                                <fa [name]="'sort-desc'"></fa>
                            </div>
                        </div>
                    </a>
                    <ul class="dropdown-menu dropdown-user">
                        <li>
                            <a (click)="edit()">Editar cuenta</a>
                        </li>
                        <li>
                            <a (click)="logout()">Cerrar sesión</a>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</nav>
