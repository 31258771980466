import { EstadisticasEvaluacionRecomendadaComponent } from "@puntaje/puntaje/core"
import { EvaluacionAdminComponent, EvaluacionesMasivasComponent } from "@puntaje/puntaje/core"
import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import { Routes, RouterModule } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"

export const routes: Routes = [
    {
        path: "evaluaciones_masivas",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EvaluacionesMasivasComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "evaluaciones/:id",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EvaluacionAdminComponent, canActivate: [AuthGuard] }]
    }
]

export const routing = RouterModule.forChild(routes)
