<footer title="Informacion de la empresa" class="print-hide">
    <div class="txt-company-footer">
        <a href="http://ogr.cl/">
            <img
                class="footer-logo"
                src="https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/shared/logo_ogr_light.png"
                alt="{{ config.plataforma.info.companyName }}"
                lang="en"
            />
        </a>
        <p>Todos los Derechos Reservados a "Open Green Road S.A." ®</p>
    </div>
</footer>
