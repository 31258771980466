import { PuntajePlanClasesModule } from "@puntaje/puntaje/core"
import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"

import { AdminPlanClasesRoutingModule } from "./admin-plan-clases-routing.module"

import { AdminLayoutsModule } from "../layouts"
import { SharedModule } from "@puntaje/puntaje/new-modules/shared"
import { PlanClasesModule } from "@puntaje/puntaje/new-modules/plan-clases"

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        PlanClasesModule,
        AdminLayoutsModule,
        AdminPlanClasesRoutingModule,
        SharedModule,
        PuntajePlanClasesModule
    ]
})
export class AdminPlanClasesModule {}
