import { Component, Directive, ViewChild, AfterContentInit, Input } from "@angular/core"
import { AuthService } from "@puntaje/shared/core"

/*#################################

Template simple para páginas una vez que usuario se haya logeado. Incluye el menú al costado izquierdo y el navbar en la parte
superior.
Implementa una sección de contenido, un título, un subtítulo y un set de tabs (no son requeridos, pero título puede incluir
un valor por defecto si no está definido).

logged-layout-toolnavbar
logged-layout-titulo
logged-layout-subtitulo
logged-layout-contenido
logged-layout-tabs

#################################*/

@Component({
    templateUrl: "logged_layout.component.html",
    selector: "logged-layout",
    styleUrls: ["logged_layout.scss"]
})
export class LoggedLayoutComponent implements AfterContentInit {
    @ViewChild("loggedLayoutTabs", { static: true }) loggedLayoutTabs
    @ViewChild("loggedLayoutContenido", { static: true }) loggedLayoutContenido
    showTabs: boolean = false
    showContenido: boolean = false
    @Input() subtitle: string = ""
    @Input() hideTitleOnPrint: boolean = false

    constructor(protected authService: AuthService) {}

    ngAfterContentInit() {
        this.showTabs = this.loggedLayoutTabs.nativeElement.children.length > 0
        this.showContenido = this.loggedLayoutContenido.nativeElement.children.length > 0
    }

    isLoggedIn() {
        return this.authService.isLoggedIn()
    }
}

@Directive({
    selector:
        "logged-layout-titulo, logged-layout-subtitulo, logged-layout-contenido, logged-layout-tabs, logged-layout-toolnavbar"
})
export class LoggedLayoutComponentTags {}
