import { Component, OnInit, ViewChild, AfterViewInit, ElementRef } from "@angular/core"
import { Router, ActivatedRoute } from "@angular/router"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { AuthService } from "@puntaje/shared/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "landing",
    templateUrl: "landing.component.html",
    styleUrls: ["landing.component.scss"]
})
export class LandingComponent implements OnInit {
    config: typeof config = config

    private totalHeight: number
    private windowHeight: number

    contactPhone: string = ""
    contactMail: string = ""

    constructor(private router: Router, private route: ActivatedRoute, public authService: AuthService) {
        this.contactPhone = config.plataforma.info.contacto.telefono ? config.plataforma.info.contacto.telefono : ""
        this.contactMail = config.plataforma.info.contacto.mail ? config.plataforma.info.contacto.mail : ""
    }

    ngOnInit() {
        if (this.authService.isLoggedIn()) {
            this.router.navigate(["home"])
        }
    }

    resizeElements() {
        this.windowHeight = window.innerHeight
        var cajas = document.querySelectorAll(".caja"),
            i
            /*for (i = 0; i < cajas.length; ++i) {
			(<HTMLElement>cajas[i]).style.minHeight = this.windowHeight+"px";
		}*/
        ;(<HTMLElement>cajas[0]).style.minHeight = this.windowHeight + "px"
        //(<HTMLElement>document.querySelector('.mascara')).style.minHeight = (this.windowHeight * cajas.length)+"px";
    }
}
